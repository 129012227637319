export const socialIconList = [
  {
    name: 'instagram',
    prefix: 'fab', // fab prefix for brands, fas for solid icons
    link: 'https://instagram.com/zuzanna.nachyla',
  },
  {
    name: 'facebook',
    prefix: 'fab',
    link: 'https://facebook.com/zuzanna.nachyla.pole.dance',
  },
  {
    name: 'youtube',
    prefix: 'fab',
    link: 'https://youtube.com/channel/UC8-BnTjB4MA2x7VQpen6MGA?sub_confirmation=1',
  },
  // Add further social links with the icon of choice and link here
  // check https://fontawesome.com/icons?d=gallery&s=brands for other brand icons
];
